import ErrorPage from 'pages/data-not-found';
import { useEffect } from 'react';
import { Suspense } from 'react';
import { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { changePage } from 'store/slices/PageSlice';
import Loadable from 'ui-component/Loadable';

const AskAsato = Loadable(
  lazy(() => import('air/AskAsato').catch(() => ({ default: () => <ErrorPage errorType="remoteComponentError" /> })))
);
const AskAsatoService = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePage('ask-asato'));
  }, []);
  return (
    <Suspense fallback={<div>Loading Ask Asato...</div>}>
      <AskAsato />
    </Suspense>
  );
};

export default AskAsatoService;
