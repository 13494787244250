import { StyledTypography } from 'styled-components/global';
import { StyledList, StyledListItem, StyledPopover } from '../styles';
import { Box } from '@mui/material';
import { useState } from 'react';
import { ArrowRight2 } from 'iconsax-react';

const ChildPopover = ({ routeChildren, open, handleClose }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  console.log('routeChildren', routeChildren);
  if (!routeChildren || !routeChildren.length) return null;
  return (
    <StyledPopover
      open={open}
      handleClose
      onClose={handleClose}
      onMouseEnter={() => setPopoverOpen(true)}
      onMouseLeave={() => setPopoverOpen(false)}
    >
      <StyledList>
        {routeChildren.map((child, index) => (
          <StyledListItem key={index}>
            <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <StyledTypography className="popover-text">{child.name}</StyledTypography>
              {child?.children && child.children.length && <ArrowRight2 />}
            </Box>
            <Box position={'relative'}>
              <ChildPopover routeChildren={child.children} open={popoverOpen} handleClose={() => setPopoverOpen(false)} />
            </Box>
          </StyledListItem>
        ))}
      </StyledList>
    </StyledPopover>
  );
};

export default ChildPopover;
