import React from 'react';
import OnHoverToolTip from 'ui-component/Tooltips/OnHoverToolTip';
import { StyledCard, IconBox, LabelBox, LabelTypography, InnerBox } from '../../Styles/SelectionCardStyles';

const SelectionCard = ({ icon, label, isSelected, onClick, width }) => {
  const isLongValue = label?.length > 33;

  return (
    <StyledCard isSelected={isSelected} width={width} variant="outlined" onClick={onClick}>
      <InnerBox>
        <IconBox component="img" src={icon} alt={label} />

        <LabelBox>
          <OnHoverToolTip variant="children" placement="bottom-start" title={isLongValue ? label : ''}>
            <LabelTypography variant="body2">{label}</LabelTypography>
          </OnHoverToolTip>
        </LabelBox>
      </InnerBox>
    </StyledCard>
  );
};

export default SelectionCard;
