import { useState } from 'react';
import { StyledTabExternalLinkWrapper, StyledTabWrapper } from '../styles';
import TabContent from './tab-content';

const SidebarTab = ({ route, hover }) => {
  const [open, setOpen] = useState(false);

  return route?.external ? (
    <StyledTabExternalLinkWrapper
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      href={route?.onClickPath ? route?.onClickPath : route?.path}
      target="_blank"
      rel="noreferrer"
      data-test-id={`sidebar-tab-${route.name}`}
    >
      <TabContent route={route} hover={hover} open={open} setOpen={setOpen} />
    </StyledTabExternalLinkWrapper>
  ) : (
    <StyledTabWrapper
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      to={route?.onClickPath ? route?.onClickPath : route?.path}
      data-test-id={`sidebar-tab-${route.name}`}
    >
      <TabContent route={route} hover={hover} open={open} setOpen={setOpen} />
    </StyledTabWrapper>
  );
};

export default SidebarTab;
