import React from 'react';

export const getSidebarTabs = (routes) => {
  return routes?.reduce(
    (acc, route) => {
      if (route.sidebar) {
        if (route.position === 'top') {
          acc.topTabs.push({
            ...route
          });
        } else if (route.position === 'middle') {
          acc.middleTabs.push({
            ...route
          });
        } else if (route.position === 'bottom') {
          acc.bottomTabs.push({
            ...route
          });
        }
      }
      return acc;
    },
    { topTabs: [], middleTabs: [], bottomTabs: [] }
  );
};

export const MemoizedSvg = React.memo(({ svg }) => <span>{svg}</span>);

export const getSidebarTabsWithChildren = (routes) => {
  return routes?.reduce((acc, route) => {
    if (route.sidebar) {
      acc.push({ ...route });
    }
    return acc;
  }, []);
};
