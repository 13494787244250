import { Box, Typography } from '@mui/material';
import BetaTag from './beta-tag';
import React from 'react';
import { motion } from 'framer-motion';
import ChildPopover from './child-popover';
import { ArrowRight2 } from 'iconsax-react';

const MemoizedSvg = React.memo(({ svg }) => {
  return <div style={{ display: 'flex', alignItems: 'center', padding: 2 }}>{svg}</div>;
});
const TabContent = ({ route, hover, open, setOpen }) => {
  return (
    <>
      <BetaTag beta={route.beta} hover={hover} />
      <Box display={'flex'} alignItems={'center'}>
        <MemoizedSvg svg={route.svg} />
        {/* {hover && <Typography noWrap>{route.name}</Typography>} */}
        <motion.div
          initial={{ scaleX: 0, opacity: 0, width: 0 }}
          animate={{ scaleX: hover ? 1 : 0, opacity: hover ? 1 : 0, width: hover ? '120px' : 0 }}
          transition={{ duration: 0.08, ease: 'easeInOut' }}
          style={{
            marginLeft: hover ? '8px' : 0,
            transformOrigin: 'left',
            overflow: 'hidden', // Ensures the box content doesn't spill out
            display: 'inline-block' // Prevents the empty box issue
          }}
          exit={{ scaleX: 0, opacity: 0, width: 0 }} // Smooth exit
        >
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography noWrap>{route.name}</Typography>
            {route?.children && route.children.length && <ArrowRight2 />}
          </Box>
        </motion.div>
      </Box>
      <Box position={'relative'}>
        <ChildPopover open={open} routeChildren={route.children} handleClose={() => setOpen(false)} />
      </Box>
    </>
  );
};

export default TabContent;
