import { Box } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import React from 'react';
import { StyledBox, StyledErrorContainer, StyledTypography } from 'styled-components/global';

const RemoteComponentError = ({ name = 'Ask Asato' }) => {
  return (
    <StyledErrorContainer fullScreen>
      <StyledBox layoutStyle="flexCenter" gap={1}>
        <CloseCircle size={70} />
        <Box>
          <StyledTypography weight={'bolder'} size={'large'}>
            Oops!
          </StyledTypography>
          <StyledTypography>Failed to fetch {name}</StyledTypography>
        </Box>
      </StyledBox>
    </StyledErrorContainer>
  );
};

export default RemoteComponentError;
