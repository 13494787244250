import { AccordionSummary, Box, List, ListItem } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

const TAB_STYLE = {
  textDecoration: 'none',
  cursor: 'pointer',
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  padding: '6px',
  color: '#4e5663',
  position: 'relative',
  '&:hover': {
    background: '#d4e4fd',
    color: '#2a76fa !important'
  },
  '&.active': {
    background: '#d4e4fd',
    color: '#2a76fa !important'
  }
};

export const StyledTabWrapper = styled(motion(NavLink))({
  ...TAB_STYLE
});

export const StyledTabExternalLinkWrapper = styled(motion('a'))({
  ...TAB_STYLE
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  padding: 0,
  background: 'transparent',
  margin: 0
});

export const StyledMotionDiv = styled(motion.div)({
  width: 'fit-content',
  borderRight: '1px solid #d4e4fd',
  backgroundColor: '#F4F8FE',
  overflow: 'hidden'
});

export const sidebarMotionStyle = {
  // position: 'relative',
  borderRight: '1px solid #d4e4fd',
  backgroundColor: '#F4F8FE',
  width: 'fit-content'
  // overflow: 'hidden'
};

export const StyledBetaDotWrapper = styled(Box)({
  position: 'absolute',
  top: -2,
  right: -4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#2A76F4'
});

export const StyledPopover = styled(Box)(({ open }) => ({
  position: 'absolute',
  animation: 'appear 0.3s ease-in-out',
  '@keyframes appear': {
    from: {
      opacity: 0,
      transform: 'scale(0.95)'
    },
    to: {
      opacity: 1,
      transform: 'scale(1)'
    }
  },
  width: 'fit-content',
  height: 'fit-content',
  display: open ? 'block' : 'none',
  backgroundColor: '#fff',
  borderRadius: 8,
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  top: -19,
  left: 0
}));

export const StyledList = styled(List)({
  minWidth: 150,
  borderRadius: 8,
  padding: '0.3rem',
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
});

export const StyledListItem = styled(ListItem)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.4rem',
  borderRadius: 4,
  '&:hover': {
    backgroundColor: '#D4E4FD'
  },
  '.popover-text': {
    color: '#4e5663',
    fontSize: '0.9rem',
    '&:hover': {
      color: '#2A76F4',
      fontWeight: 600
    }
  }
});
