export const errorFontStyle = {
  // * Works if you spread this in StyledTypography component
  code: {
    variant: 'h1',
    weight: 'boldest',
    size: 'xl'
  },
  title: {
    variant: 'h4',
    weight: 'bold',
    size: 'large'
  },
  message: {
    variant: 'p',
    weight: 'normal',
    size: 'medium'
  }
};

export const ERROR_MAP = {
  404: {
    text: {
      title: 'Oops! Data Not Found',
      message: 'There is some error retrieving page data'
    },
    code: 404,
    styledProps: errorFontStyle
  },
  500: {
    text: {
      title: `Oops! We couldn&apos;t load that`,
      message: `Sorry, this isn&apos;t supposed to happen, but our team is aware and is working on a fix. Hang tight! Need support instead? Write
          to us at <a href="mailto:support@asato.ai">support@asato.ai</a>`
    },
    code: 500,
    styledProps: errorFontStyle
  }
};
