import { styled } from '@mui/material/styles';
import { Card, Box, Typography } from '@mui/material';

export const StyledCard = styled(Card)(({ isSelected, width }) => ({
  width: width || 'fit-content',
  margin: 0,
  borderColor: isSelected ? '#1976d2' : 'rgba(0, 0, 0, 0.12)',
  backgroundColor: isSelected ? '#EDF4FE' : '#fff',
  borderRadius: '10px',
  ':hover': {
    boxShadow: '0px 0px 5px grey'
  }
}));

export const IconBox = styled(Box)({
  height: '70px',
  width: '115px',
  flexShrink: 0,
  padding: '1rem',
  backgroundColor: '#F4F6F9',
  borderRadius: '5px'
});

export const InnerBox = styled(Box)({
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '124px',
  cursor: 'pointer',
  width: '130px',
  gap: '8px'
});

export const LabelBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
  width: 110
});

export const LabelTypography = styled(Typography)({
  fontSize: '13px',
  fontWeight: 550,
  lineHeight: '15px',
  textAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  maxHeight: '30px',
  color: '#434C5B'
});
