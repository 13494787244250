import { Toolbar, Box } from '@mui/material';
import Header from 'layout/MainLayout/Header';
import { Outlet } from 'react-router-dom';
import InternetConnectionCard from 'ui-component/common/internet-connection';
import Sidebar from './sidebar';
import { StyledScrollbarWrapper } from 'styled-components/global';

const Layout = ({ children }) => {
  return (
    <StyledScrollbarWrapper>
      <Toolbar sx={{ backgroundColor: '#021A42', zIndex: 1000 }}>
        <Header />
      </Toolbar>
      <Box display="flex" position={'relative'} minHeight={'calc(100vh - 80px)'}>
        <Sidebar />
        <Box width="100%" padding={1} maxHeight={'calc(100vh - 80px)'} overflow={'auto'}>
          {children}
          <Outlet />
        </Box>
      </Box>

      <InternetConnectionCard />
    </StyledScrollbarWrapper>
  );
};

export default Layout;
