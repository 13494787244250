import { Box, Divider } from '@mui/material';
import React, { useState } from 'react';
import { allRoutes } from 'routes/routers';
import { getSidebarTabs } from '../utils';
import { sidebarMotionStyle } from '../styles';
import SidebarTab from './sidebar-tab';

const Sidebar = ({ isStorybook = false, sidebarData }) => {
  const [hover, setHover] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const SIDEBAR_TABS = isStorybook ? sidebarData : getSidebarTabs(allRoutes);

  const handleMouseEnter = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setHover(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHover(false);
    }, 1000); // Timeout of one second
    setHoverTimeout(timeout);
  };

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={sidebarMotionStyle} data-test-id={'sidebar'}>
      <Box padding={1} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height="100%">
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {SIDEBAR_TABS.topTabs.map((route, index) => (
            <SidebarTab key={index} route={route} hover={hover} />
          ))}
          <Divider />
          {SIDEBAR_TABS.middleTabs.map((route, index) => (
            <SidebarTab key={index} route={route} hover={hover} />
          ))}
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {SIDEBAR_TABS.bottomTabs.map((route, index) => (
            <SidebarTab key={index} route={route} hover={hover} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
