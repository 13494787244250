import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styles from './styles.module.css';
import CatalogChip from '../CatalogChip';

function CatalogDetailCard({
  icon,
  title,
  descriptionList = [],
  inStock = false,
  productLicense = false,
  primaryId = '',
  secondaryId = '',
  potentialSavings,
  price,
}) {
  const RenderCardContent = () => {
    return (
      <Box className={styles.cardContent}>
        <Box className={styles.titleContainer}>
          <Typography variant="h6">{title}</Typography> {' | '}
          <CatalogChip text={inStock ? 'In Stock' : 'Out of Stock'} color={inStock ? 'success' : 'error'} className={styles.inStockChip} />
        </Box>

        <Box className={styles.idContainer}>
          <Typography variant="body1" fontWeight={500} fontSize={16}>ID : {primaryId}</Typography>
          {' | '}
          <Typography variant="body1" fontWeight={500} fontSize={16}>CDW : {secondaryId}</Typography>
        </Box>

        <Box className={styles.descriptionContainer}>
          {descriptionList.map((description, index) => (
            <Box key={index} className={styles.descriptionItem}>
              <Typography variant="body1" key={index} className={styles.descriptionLabel}>
                {description?.label}{':'}
              </Typography>
              <Typography variant="body1" key={index} className={styles.descriptionValue} fontWeight={600} fontSize={16}>
                {description?.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Grid container spacing={2} className={styles.catalogDetailCard}>
      <Grid item xs={2} className={styles.iconContainer}>
        <Box className={styles.iconBox}>
          <img src={icon} alt={title} />
        </Box>
      </Grid>
      <Grid item xs={7} flex={1}>
        <RenderCardContent />
      </Grid>
      <Grid item xs={3} className={styles.priceContainer}>
        <Box>{productLicense ? <CatalogChip text="Product Licensed" color="primary" className={styles.productLicenseChip} /> : ''}</Box>
        <Box>
          <Box className={styles.priceItem}>
            <Typography variant="body2" fontWeight={500} fontSize={16}>Potential Savings : </Typography>
            <Typography variant="body1" className={styles.price} fontWeight={600} fontSize={18}>
              $ {potentialSavings}
            </Typography>
          </Box>
          <Box className={styles.priceItem} flex={1}>
            <Typography variant="body2" fontWeight={500} fontSize={16}>Subscribed Price : </Typography>
            <Typography variant="body1" className={styles.price2} fontWeight={700} fontSize={18}>
              $ {price}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default CatalogDetailCard;
