import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from './routes';
import themes from './themes';
import NavigationScroll from 'layout/NavigationScroll';
import { IntlProvider } from 'react-intl';
import Notifications from 'ui-component/Notifications';
import { AnimatePresence } from 'framer-motion';
import StandardErrorBoundary from 'ui-component/ErrorBoundary';
import IntercomComponent from 'ui-component/Intercom';

const App = () => {
  const customization = useSelector((state) => state?.customization);
  const locale = navigator?.language;
  return (
    <IntercomComponent>
      <ThemeProvider theme={themes(customization)}>
        <StandardErrorBoundary>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <NavigationScroll>
              <IntlProvider locale={locale} defaultLocale="en-US">
                <Notifications />
                <AnimatePresence>
                  <Routes />
                </AnimatePresence>
              </IntlProvider>
            </NavigationScroll>
          </StyledEngineProvider>
        </StandardErrorBoundary>
      </ThemeProvider>
    </IntercomComponent>
  );
};

export default App;
