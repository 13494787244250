import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = checkIfUserIsAuthenticated();

    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  const checkIfUserIsAuthenticated = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const accessToken = localStorage.getItem('accessToken');
    return isLoggedIn || !!accessToken;
  };

  return <>{children}</>;
};

export default RequireAuth;
