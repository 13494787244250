import React from 'react'
import { Breadcrumbs, Link, Typography } from '@mui/material'

function Breadcrump({ breadcrumbList = [] }) {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {breadcrumbList.map((breadcrumb, index) => {
        const isLast = index === breadcrumbList.length - 1;
        
        return isLast ? (
          <Typography key={index} color="primary">
            {breadcrumb.label}
          </Typography>
        ) : (
          <Link underline="hover" key={index} href={breadcrumb.path} color="inherit">
            {breadcrumb.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  )
}

export default Breadcrump