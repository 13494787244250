import React from 'react'
import Breadcrump from 'ui-component/Breadcrumps'
import SelectionCard from 'ui-component/cards/SelectionCard'
import CatalogDetailCard from '../components/CatalogDetailCard'

function CatalogList() {
  const breadcrumbList = [
    {
      label: 'Datasets',
      path: '/datasets'
    },
    {
      label: 'Catalog',
      path: '/datasets/catalogs'
    }
  ]

  const descriptionList = [
    {
      label: "License Quantity",
      value: "1 seat"
    },
    {
      label: "License Type",
      value: "Subscription Renewal"
    },
    {
      label: "License Validation Period",
      value: "Annual"
    },
    {
      label: "Operating System",
      value: "Windows"
    },
    {
      label: "Product Line",
      value: "AutoCAD"
    },
    {
      label: "CDW Price",
      value: "$100"
    }
  ]

  return (
    <div style={{ padding: '20px' }}>
      <Breadcrump breadcrumbList={breadcrumbList} />

      <SelectionCard 
        label="Dataset 1"
        icon="https://stbackendservicedev.blob.core.windows.net/icons/entra.svg"
        isSelected={false}
        onClick={() => {}}
      />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <CatalogDetailCard 
        icon="https://stbackendservicedev.blob.core.windows.net/icons/entra.svg"
        title="AutoCAD - subscription Renewal (Annual) - 1 seat"
        descriptionList={descriptionList}
        inStock={true}
        productLicense={true}
        primaryId="CHGJ-234567890"
        secondaryId="0987654321"
        potentialSavings={6.22}
        price={100}
        currency="USD"
      />

    </div>
  )
}

export default CatalogList