import { Button, Typography } from '@mui/material';
// import MainLayout from 'layout/MainLayout';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { CloseCircle } from 'iconsax-react';
import Layout from 'layout/app-layout';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    justifyContent: 'center',
    minHeight: '80vh',
    maxWidth: '500px',
    rowGap: '1rem',
    margin: 'auto'
  },
  image: {
    maxWidth: 300
  }
}));

const Error500 = ({ reset }) => {
  const classes = useStyles();

  return (
    <Layout>
      <div className={classes.root}>
        <CloseCircle size={70} />
        <Typography variant="h2" maxWidth={600}>
          Oops! We couldn&apos;t load that.
        </Typography>
        <Typography>
          Sorry, this isn&apos;t supposed to happen, but our team is aware and is working on a fix. Hang tight! Need support instead? Write
          to us at <a href="mailto:support@asato.ai">support@asato.ai</a>.
        </Typography>
        <Button sx={{ padding: '0.5rem 1.5rem', fontSize: '1rem' }} variant="outlined" onClick={reset}>
          Retry
        </Button>
      </div>
    </Layout>
  );
};

export default Error500;
