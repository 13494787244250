const defaultColor = '#4E5663';

export const AskAsatoIcon = ({ fill = defaultColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path
        id="Vector"
        d="M19.2984 16.5341L19.0863 16.7927L19.2444 17.0875L20.954 20.2747L20.9542 20.2751C20.9761 20.3158 20.9781 20.3477 20.9743 20.3727C20.9699 20.4013 20.9559 20.4314 20.9331 20.4563C20.9102 20.4813 20.8855 20.4937 20.8666 20.498C20.8521 20.5012 20.83 20.5027 20.7947 20.4854L20.7943 20.4851L17.2319 18.7388L16.9833 18.6169L16.7481 18.7629C15.0976 19.7879 13.1239 20.3844 11 20.3844C5.17656 20.3844 0.5 15.9094 0.5 10.4422C0.5 4.97497 5.17656 0.5 11 0.5C16.8234 0.5 21.5 4.97497 21.5 10.4422C21.5 12.7363 20.6801 14.8493 19.2984 16.5341Z"
        stroke={fill}
        strokeWidth="1.3"
      />
      <path
        id="Vector"
        d="M14.7497 11.1579C14.5225 11.3853 14.3893 11.6863 14.3729 12.0079H14.3723L14.371 12.0738V14.6411H12.1604V12.2256C12.1604 11.6677 12.5498 11.1764 13.0862 11.0573L13.1203 11.0496V10.7889H12.7025C12.3731 10.7889 12.0613 10.917 11.8241 11.1495L11.8209 11.1527C11.8209 11.1527 11.8171 11.1566 11.8146 11.1585C11.5874 11.3853 11.4543 11.6863 11.4379 12.0085H11.4372L11.436 12.0745V14.6411H9.22537V12.2256C9.22537 11.6677 9.61474 11.1764 10.1511 11.0573L10.1852 11.0496V10.7889H9.76745C9.43299 10.7889 9.11809 10.9202 8.87955 11.1579C8.65237 11.3853 8.51922 11.6863 8.50281 12.0079H8.50218L8.50092 12.0738V14.6411H6.28906V11.997C6.28906 11.3372 6.81789 10.8011 7.46725 10.8011H8.20054V10.2579H7.46725C6.81726 10.2579 6.28906 9.72115 6.28906 9.06203V6.4416H8.50029V9.00823C8.50029 9.7173 9.06824 10.2938 9.76682 10.2938H10.1846V10.0344L10.1505 10.0267C9.6141 9.90754 9.22474 9.41625 9.22474 8.85834V6.44096H11.4353V9.00759C11.4353 9.35476 11.5704 9.67951 11.8152 9.92356L11.8228 9.93124C12.0607 10.1644 12.3725 10.2925 12.7012 10.2925H13.119V10.0331L13.0849 10.0254C12.5485 9.90626 12.1592 9.41497 12.1592 8.85706V6.43968H14.3698V9.00631C14.3698 9.71538 14.9377 10.2919 15.6363 10.2919H15.6483V10.787H15.6363C15.3018 10.787 14.9869 10.9183 14.7484 11.156L14.7497 11.1579Z"
        stroke={fill}
        strokeWidth="1.3"
      />
    </svg>
  );
};

export const AccessIcon = ({ fill = defaultColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
      <path
        d="M19.3233 8.36568V14.6357C19.3233 15.6623 18.7733 16.6157 17.8841 17.1382L12.4391 20.2823C11.55 20.7957 10.45 20.7957 9.55165 20.2823L4.10665 17.1382C3.21748 16.6249 2.66748 15.6715 2.66748 14.6357V8.36568C2.66748 7.33902 3.21748 6.38564 4.10665 5.86314L9.55165 2.71898C10.4408 2.20565 11.5408 2.20565 12.4391 2.71898L17.8841 5.86314C18.7733 6.38564 19.3233 7.32985 19.3233 8.36568Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9998 10.5841C12.1794 10.5841 13.1357 9.62788 13.1357 8.44829C13.1357 7.2687 12.1794 6.3125 10.9998 6.3125C9.82026 6.3125 8.86401 7.2687 8.86401 8.44829C8.86401 9.62788 9.82026 10.5841 10.9998 10.5841Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6666 15.7715C14.6666 14.1215 13.0258 12.7832 10.9999 12.7832C8.97409 12.7832 7.33325 14.1215 7.33325 15.7715"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
